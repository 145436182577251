.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.modal-content button {
  cursor: pointer;
  margin-bottom: -5px;
  background-color: white;
  color: black;
  font-weight: bold;
  float: right;
}

.modal-content button:hover {
  filter: drop-shadow(4px 4px 2px black);
}