.enlaces {
    display: flex;
    margin-left: auto;
    flex-direction: row;
    justify-content: flex-end;
    height: 30px;
    background-color: #4493EF
}

.enlaces a {
    margin-left: -4px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    margin-top: 25px;
}

.enlaces a:hover {
    scale: 1.1;
    filter: drop-shadow(4px 4px 4px black);
}

.categoria-btn {
    position: relative;
    cursor: pointer;
    margin-right: 40px;
    margin-left: -4px;
    text-decoration: none;
    margin-right: 20px;
    margin-top: 25px;
}

.categoria-btn span {
    font-size: 20px;
    font-weight: 600;
    color: black;
    font-weight: bold;
}

.categoria-btn span:hover {
    scale: 1.1;
    filter: drop-shadow(4px 4px 4px black);
}

.categorias-menu {
    display: none;
    position: absolute;
    top: 28px;
    right: -22px;         /* al habilitar el combo y kit volver a right: -92px;*/
    width: 235px;
    height: 600px;          /* al habilitar el combo y kit volver a height: 650px;*/
    background-color: /*#FFF*/#4493EF;
    border: 1px solid #ccc;
    filter: drop-shadow(4px 4px 2px grey);
    padding: 0px;
    z-index: 1;

    text-align: center;
}

.categoria-btn:hover .categorias-menu {
    display: flex;
    flex-direction: column;
}

.categorias-menu a {
    font-size: 16x;
    text-decoration: none;
    color: black;
    margin-left: 20px;
    transition: background-color 0.3s;
    margin-top: 15px;
}

.categorias-menu a:hover {
    background-color: /*#f0f0f0*/#4493EF;
}