.inicioContenedor {
  
}

.despues-navbar {
    height: 250px;
    background-image: url('/public/images/fondo-navbar1.svg');
    background-size: cover;
    background-position: center;
}

.componente {
    background-color: #E5E5E5;
    padding: 1px;
}

.botones {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    margin-bottom: 125px;
    /*width: 85%;
    margin: auto;*/
    margin-top: 20px;
}

.boton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 200px;
    border: 2px solid #ccc;
    margin: 5px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
    background-color: /*rgb(152, 142, 142)*/white;
    filter: drop-shadow(4px 4px 2px grey);
}

.boton:hover {
    transition: all 300ms;
    color: white;
    background-color: rgb(152, 142, 142);
}

.boton img {
    max-width: 150px;
    /* Ajusta según sea necesario */
    max-height: 150px;
    /* Ajusta según sea necesario */
    margin-bottom: 5px;
}

.categorias {
    text-align: center;
    color: black;
    font-size: 30px;
    font-weight: bold;
    position: relative;
    filter: drop-shadow(4px 4px 2px grey);
    margin-top: 120px;
    margin-bottom: 40px;
}


.seguime {
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #E5E5E5;
    height: 350px;
    margin-top: 80px;
    margin-bottom: 80px;*/
    margin-top: 100px;
    margin-bottom: -50px;
}

.seguime-img {
    background-image: url('/public/images/fondo-instagram2.png');
    background-size: cover;
    height: 120px;
    width: 250px;
    filter: drop-shadow(4px 4px 2px grey)
}

.seguime-img:hover {
    opacity: 0.8;
    /* Puedes ajustar la opacidad según tu preferencia */
}

