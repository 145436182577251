.contenido {
    background-color: white;
    margin-top: 48px;
}

.contenido1 {
    display: grid;
    justify-content: center;
    justify-items: center;
}

.tituloInicio {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 36px;
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin-top: -65px;
    padding: 128px;
    margin-bottom: -37px;
}

.imgLogo{
    height: 220px;
    filter: drop-shadow(4px 4px 2px grey);
    margin-top: -15px;
}