body {
    background-size: contain;
    background-attachment: fixed;
    background-color: rgb(255, 255, 255);
}

.layout {
    width: 100%;
    margin: 0 auto;
    display: flexbox;
    justify-content: center;
    align-items: center;
}