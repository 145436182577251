.pagina {
    justify-content: flex-start;
}

.tituloProductos {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 40px;
    color: black;
    filter: drop-shadow(4px 4px 2px grey);
    margin-top: 0px;
}

.botonesCategorias-productos {
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    gap: 5px;
    background-color: rgb(152, 142, 142);
    filter: drop-shadow(4px 4px 2px grey);
}

button {
    cursor: pointer;
}

.botones-productos {
    gap: 3px;
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
}

.botonActivo {
    background-color: rgb(152, 142, 142);
    color: white;
}

.imagenesGrupo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.imghome {
    /*width: 250px;*/
    background-size: cover;
    padding: 5px;
    object-fit: contain;
    margin-bottom: 20px;
    cursor: pointer;


    max-width: 350px;
    max-height: 350px;
    width: 100%;
}



.imghome:hover {
    transition: all 300ms;
    filter: drop-shadow(4px 4px 2px grey);
    scale: 1.1;
}

.imagenesGrupo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.imagen-container {
    position: relative;
    margin: 10px;
}

.imagen-name {
    position: absolute;
    /*top: 205px;*/
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    border-radius: 5px;
    margin-top: -18px;
}

/* Estilos CSS para el nombre en el ModalImagenes.css */
.image-name-modal {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
}

.antes-footer {
    height: 250px;
    background-image: url('/public/images/fondo-footer.svg');
    /*background-attachment: fixed;*/
    background-size: cover;
    background-position: center;
}