.scroll-to-top {
    position: fixed;
    bottom: 20px;
    left: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    cursor: pointer;
}

.scroll-to-top.show {
    opacity: 1;
    visibility: visible;
}