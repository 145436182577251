.footer {
    text-align: center;
    border-top: 1px solid #4493EF;
    color: black;
    height: 70px;
    background-color: #4493EF;
}

.buttonfooter {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
}

.buttonfooter:hover {
    transition: all 300ms;
    filter: drop-shadow(4px 4px 2px black);

}